<div class="our-services" id="ourServicesSection">
    <div class="section-content">
        <br><br>
        <h1 id="ourServices">NUESTROS SERVICIOS</h1>
        <div class="our-services-container">
            <div class="services-items">
                <div class="service-item" (click)="selectItem(1)" [ngClass]="{'selected': currentItem == 1}">
                    <div class="service-item-row">
                        <div class="icon medical_division"></div>
                    </div>
                    <div class="service-item-row">
                        <div class="title"><span>MEDICAL<br>DIVISION</span></div>
                    </div>
                </div>
                <div class="service-item" (click)="selectItem(2)" [ngClass]="{'selected': currentItem == 2}">
                    <div class="service-item-row">
                        <div class="icon safety_division"></div>
                    </div>
                    <div class="service-item-row">
                        <div class="title"><span>SAFETY<br>DIVISION</span></div>
                    </div>
                </div>
                <div class="service-item" (click)="selectItem(3)" [ngClass]="{'selected': currentItem == 3}">
                    <div class="service-item-row">
                        <div class="icon consulting_division"></div>
                    </div>
                    <div class="service-item-row">
                        <div class="title"><span>CONSULTING<br>DIVISION</span></div>
                    </div>
                </div>
                <div class="service-item" (click)="selectItem(4)" [ngClass]="{'selected': currentItem == 4}">
                    <div class="service-item-row">
                        <div class="icon risk_division"></div>
                    </div>
                    <div class="service-item-row">
                        <div class="title"><span>RISK<br>CONTROL<br>DIVISION</span></div>
                    </div>
                </div>
                <div class="service-item" (click)="selectItem(5)" [ngClass]="{'selected': currentItem == 5}">
                    <div class="service-item-row">
                        <div class="icon tech_division"></div>
                    </div>
                    <div class="service-item-row">
                        <div class="title"><span>HIGH<br>TECH<br>DIVISION</span></div>
                    </div>
                </div>
            </div>
            <div class="image-container background" [style.background-image]="'url(../../assets/servicios/' + img + ')'">
                <div class="service-text" *ngIf="currentItem == 1">
                    <p (mouseenter)="onHover('salud_ocupacional_1.jpg')">SALUD OCUPACIONAL</p>
                    <p (mouseenter)="onHover('medicina_preventiva_1.jpg')">MEDICINA PREVENTIVA</p>
                    <p (mouseenter)="onHover('laboratorios_1.jpg')">LABORATORIOS</p>
                    <p (mouseenter)="onHover('zona_protegida_1.jpg')">ZONA PROTEGIDA</p>
                </div>
                <div class="service-text" *ngIf="currentItem == 2">
                    <p (mouseenter)="onHover('seguridad_industrial_1.jpg')">SEGURIDAD INDUSTRIAL</p>
                    <p (mouseenter)="onHover('seguridad_sanitaria_1.jpg')">SEGURIDAD SANITARIA</p>
                    <p (mouseenter)="onHover('proteccion_civil_1.jpg')">PROTECCIÓN CIVIL</p>
                    <p (mouseenter)="onHover('capacitacion_1.jpg')">CAPACITACIONES</p>
                </div>
                <div class="service-text" *ngIf="currentItem == 3">
                    <p (mouseenter)="onHover('ambiente_1.jpg')">PROTECCIÓN AMBIENTAL</p>
                    <p (mouseenter)="onHover('seguridad_alimentaria_1.jpg')">SEGURIDAD ALIMENTARIA</p>
                    <p (mouseenter)="onHover('calidad_1.jpg')">CALIDAD DE PROCESOS</p>
                    <p (mouseenter)="onHover('legal_1.jpg')">ASESORÍA EN EL CUMPLIMIENTO LEGAL NORMATIVO</p>
                    <p (mouseenter)="onHover('gestoria_1.jpg')">GESTORÍA Y TRAMITOLOGÍA</p>
                </div>
                <div class="service-text" *ngIf="currentItem == 4">
                    <p (mouseenter)="onHover('riesgos_1.jpg')">ANÁLISIS Y GESTIÓN INTEGRAL DE RIESGOS</p>
                    <p (mouseenter)="onHover('incendios_1.jpg')">SISTEMAS DE PROTECCIÓN CONTRA INCENDIOS</p>
                    <p (mouseenter)="onHover('equipo_proteccion_1.jpg')">EQUIPO DE PROTECCIÓN PERSONAL</p>
                    <p (mouseenter)="onHover('ingenieria_1.jpg')">DISEÑO E INGENIERÍA DE CONTROLES</p>
                </div>
                <div class="service-text" *ngIf="currentItem == 5">
                    <p (mouseenter)="onHover('hardware_1.jpg')">HARDWARE DE RECONOCIMIENTO</p>
                    <p (mouseenter)="onHover('geografica_1.jpg')">SISTEMAS DE INFORMACIÓN GEOGRÁFICA APLICADAS</p>
                    <p (mouseenter)="onHover('mantenimiento_1.jpg')">SISTEMAS INTELIGENTES DE MANTENIMEINTO</p>
                    <p (mouseenter)="onHover('programacion_1.jpg')">PROGRAMACIÓN Y AUTOMATIZACIÓN</p>
                    <p (mouseenter)="onHover('redes_1.jpg')">INSTALACIÓN Y MANTENIMIENTO DE REDES</p>
                </div>
            </div>
        </div>
    </div>

    <div class="section-content-m">
        <br><br>
        <h1 id="ourServices">NUESTROS SERVICIOS</h1>
        <div class="our-services-container">
            <div class="service-container medical-division">
                <div class="title">
                    <div class="icon"></div>
                    <div class="text">Medical division</div>
                </div>
                <div class="bg service-content">
                    <ul>
                        <li>Salud ocupacional</li>
                        <li>Medicina preventiva</li>
                        <li>Laboratorios</li>
                        <li>Zona protegida</li>
                    </ul>
                </div>
            </div>

            <div class="service-container safety-division">
                <div class="title">
                    <div class="icon"></div>
                    <div class="text">Safety division</div>
                </div>
                <div class="bg service-content">
                    <ul>
                        <li>Seguridad industrial</li>
                        <li>Seguridad sanitaria</li>
                        <li>Protección civil</li>
                        <li>Capacitaciones</li>
                    </ul>
                </div>
            </div>

            <div class="service-container consulting-division">
                <div class="title">
                    <div class="icon"></div>
                    <div class="text">Consulting division</div>
                </div>
                <div class="bg service-content">
                    <ul>
                        <li>Protección ambiental</li>
                        <li>Seguridad alimentaria</li>
                        <li>Calidad de procesos</li>
                        <li>Asesoría en el cumplimiento legal normativo</li>
                        <li>Gestoría y tramitología</li>
                    </ul>
                </div>
            </div>

            <div class="service-container risk-control-division">
                <div class="title">
                    <div class="icon"></div>
                    <div class="text">Risk control division</div>
                </div>
                <div class="bg service-content">
                    <ul>
                        <li></li>
                        <li>Análisis y gestión integral de riesgos</li>
                        <li>Sistemas de protección contra incendios</li>
                        <li>Equipo de protección personal</li>
                        <li>Diseño e ingeniería de controles</li>
                    </ul>
                </div>
            </div>

            <div class="service-container high-tech-division">
                <div class="title">
                    <div class="icon"></div>
                    <div class="text">High tech division</div>
                </div>
                <div class="bg service-content">
                    <ul>                        
                        <li>Hardware de reconocimiento</li>
                        <li>Sistemas de información geográfica aplicadas</li>
                        <li>Sistemas inteligentes de mantenimiento</li>
                        <li>Programación y automatización</li>
                        <li>Instalación y mantenimiento de redes</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>