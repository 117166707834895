<div class="our-services" id="ourServicesSection">
    <div class="section-content">
        <br><br>
        <h1 id="ourServices">Seleccione la división de servicio que desee consultar:</h1>
        <div class="our-services-container grid-container">
            <div class="grid-item" *ngFor="let item of items; index as i" (click)="selectService(i)">
                <div class="background top"></div>
                <div class="background bottom"></div>
                <div class="item-logo" [ngStyle]="{'background-image': 'url(../../assets/' + (i + 1) + '.png)'}"></div>
                <div class="item-text">{{item.text}}<br>DIVISION</div>
            </div>
        </div>
        <div style="text-align: center;" id="sliderSection">
          <slider [sliderName]="'services'" [images]="images"></slider>
          <div class="go-back" (click)="goTo('ourServices')"></div>
        </div>
    </div>
</div>