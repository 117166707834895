import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sectionActive: string = '';
  @Input() menuOpened: boolean = false;
  headerClass: string = '';
  @Output() menuVerticalEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
      setTimeout(() => {
        this.headerClass = 'show';
      }, 100);
  }

  goTo(section?): void {
    if (section) {
      document.getElementById(`${section}Section`).scrollIntoView();
    } else {
      window.scrollTo(0,0);
    }
  }

  openVerticalMenu() {
    this.menuOpened = !this.menuOpened;
    this.menuVerticalEvent.emit(this.menuOpened);
  }

  download(): void {
  }
}