import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
@Injectable()
export class ContactService {
  constructor(private http: HttpClient) { }

  sendEmail(data: any): any {
    return this.http.post<any>("https://vander.com.mx/php/sendEmail.php", data);
  }
}