<div class="vertical-menu-content" [ngClass]="menuClass">
    <ul>
        <li><a href="https://vander.com.mx/public/Servicios_Vander_2022.pdf" download="Servicios 2022 - Vander.pdf" target="_blank"><span>CATÁLOGO DE SERVICIOS</span><span class="material-icons-outlined">file_download</span></a></li>
        <li (click)="goTo('whoWeAre')">¿QUIÉNES SOMOS?</li>
        <li (click)="goTo('mvv')">MISIÓN, VISIÓN & VALORES</li>
        <li (click)="goTo('opr')">NUESTROS PRINCIPIOS RECTORES</li>
        <li (click)="goTo('letter')">CARTA ABIERTA A NUESTROS CLIENTES</li>
        <li (click)="goTo('ourServices')">NUESTROS SERVICIOS</li>
        <li (click)="goTo('traceability')">TRAZABILIDAD, TRANSPARENCIA Y LEGALIDAD</li>
        <li (click)="goTo('contact')">CONTACTO</li>
    </ul>
</div>