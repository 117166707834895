<div class="contact" id="contactSection">
    <div class="section-content" id="contact">
        <br><br>
        <div>
            <h1>POLÍTICAS</h1>
            <ul>
                <li><a href="https://vander.com.mx/public/Aviso_De_Confidencialidad_Vander_2022.pdf"
                        download="Aviso de confidencialidad 2022 - Vander.pdf" target="_blank">Aviso de
                        confidencialidad</a></li>
                <li><a href="https://vander.com.mx/public/Aviso_De_Trazabilidad_Financiera_Vander_2022.pdf"
                        download="Aviso de trazabilidad financiera 2022 - Vander.pdf" target="_blank">Aviso de
                        trazabilidad financiera</a></li>
                <li><a href="https://vander.com.mx/public/Politica_Antilavado_De_La_Empresa_Vander_2022.pdf"
                        download="Política antilavado de la empresa 2022 - Vander.pdf" target="_blank">Política
                        antilavado de la empresa</a></li>
            </ul>
        </div>
        <div>
            <h1>CONTACTO</h1>
            <form class="form">
                <input type="text" placeholder="Nombre" name="name" required [(ngModel)]="name" />
                <input type="text" placeholder="E-mail" name="email" required [(ngModel)]="email" />
                <input type="text" placeholder="Asunto" name="subject" required [(ngModel)]="subject" />
                <textarea name="msg" cols="30" rows="10" placeholder="Mensaje" required [(ngModel)]="message"></textarea>
                <div class="form-message"><small>Todos los campos son requeridos</small></div>
                <div class="form-message" *ngIf="error"><small class="error">Lo sentimos, ocurrió un error, intenta en un momento</small></div>
                <div class="contact-button">
                    <input type="button" value="Enviar" [disabled]="!name || !email || !subject || !message || !isValidEmail() || loading" *ngIf="!success" (click)="submit()"/>
                    <input type="button" class="success-btn" value="Mensaje enviado" disabled *ngIf="success"/>
                </div>
            </form>
        </div>
        <div class="contact-info">
            <h1>DATOS DE CONTACTO</h1>
            <ul>
                <li>Correo eléctronico:<br><a
                        href="mailto:info.servicios@vander.com.mx">info.servicios@vander.com.mx</a></li><br>
                <li>Teléfono:<br><a href="https://wa.me/message/EXPUW3WF4DZNF1" target="_blank"><i class="fa-brands fa-whatsapp"></i>&nbsp;<span>+52 812 885 3646</span></a></li>
            </ul>
        </div>
    </div>
</div>