import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  logoClass: string = '';

  ngOnInit(): void {
      setTimeout(() => {
        this.logoClass = 'show';
        setTimeout(() => {
          this.logoClass = '';
        }, 1000);
      }, 1000);
  }
}
