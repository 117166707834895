import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {
  constructor() { }

  menuOpened: boolean = false;
  menuClass: string = '';
  @Output() closeMenuEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.menuClass = 'show';
    // }, 100);
  }

  goTo(section?): void {
    if (section) {
      document.getElementById(`${section}Section`).scrollIntoView();
    } else {
      window.scrollTo(0,0);
    }

    this.closeMenu();
  }

  closeMenu() {
    // this.menuClass = 'hide';
    this.closeMenuEvent.emit(false);
  }
}
