import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showSplashScreen: boolean = true;
  sectionActive: string = 'home';
  showVerticalMenu: boolean = false;
  private home;
  private whoWeAre;
  private mvv;
  private opr;
  private letter;
  private ourServices;
  private traceability;
  private contact;
  images: string[] = [
    '1.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg',
    '10.jpg',
    '11.jpg',
    '12.jpg',
    '13.jpg',
    '14.jpg',
    '15.jpg',
    '16.jpg',
    '17.jpg',
    '18.jpg',
    '19.jpg',
    '20.jpg',
    '21.jpg'
  ]

  ngOnInit(): void {
    setTimeout(() => {
      this.showSplashScreen = false;

      setTimeout(() => {
        this.home = document.querySelector('#home');
        this.whoWeAre = document.querySelector('#whoWeAre');
        this.mvv = document.querySelector('#mvv');
        this.opr = document.querySelector('#opr');
        this.letter = document.querySelector('#letter');
        this.ourServices = document.querySelector('#ourServices');
        this.traceability = document.querySelector('#traceability');
        this.contact = document.querySelector('#contact');
        this
      }, 500);
    }, 3000);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.home) {
      if (this.isElementInViewport(this.home)) {
        this.sectionActive = 'home';
      }
    }
    if (this.whoWeAre) {
      if (this.isElementInViewport(this.whoWeAre)) {
        this.sectionActive = 'whoWeAre';
      }
    }
    if (this.mvv) {
      if (this.isElementInViewport(this.mvv)) {
        this.sectionActive = 'mvv';
      }
    }
    if (this.opr) {
      if (this.isElementInViewport(this.opr)) {
        this.sectionActive = 'opr';
      }
    }
    // if (this.letter) {
    //   if (this.isElementInViewport(this.letter)) {
    //     this.sectionActive = 'letter';
    //   }
    // }
    if (this.ourServices) {
      if (this.isElementInViewport(this.ourServices)) {
        this.sectionActive = 'ourServices';
      }
    }
    if (this.traceability) {
      if (this.isElementInViewport(this.traceability)) {
        this.sectionActive = 'traceability';
      }
    }
    if (this.contact) {
      if (this.isElementInViewport(this.contact)) {
        this.sectionActive = 'contact';
      }
    }
  }

  isElementInViewport(elem, a?) {
    var bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  openVerticalMenu(status) {
    this.showVerticalMenu = status;
  }

}
