import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SplashComponent } from './splash/splash.component';
import { HeaderComponent } from './header/header.component';
import { SliderComponent } from './slider/slider.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { MVVComponent } from './m-vv/m-vv.component';
import { OPRComponent } from './o-pr/o-pr.component';
import { LetterComponent } from './letter/letter.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { TraceabilityComponent } from './traceability/traceability.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ContactService } from './services/contact.service';

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    HeaderComponent,
    SliderComponent,
    WhoWeAreComponent,
    MVVComponent,
    OPRComponent,
    LetterComponent,
    OurServicesComponent,
    TraceabilityComponent,
    ContactComponent,
    FooterComponent,
    VerticalMenuComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
