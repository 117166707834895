<div class="o-p-r" id="oprSection">
    <div class="section-content">
        <br><br>
        <h1 id="opr">NUESTROS PRINCIPIOS RECTORES</h1>
        <div class="text-description">
            <p class="justify">
                Los siguientes son los 10 principios rectores que fundamentan nuestros servicios para con
                nuestros clientes. Son el corazón que rigen nuestras acciones, nuestros valores y los principios
                éticos que mantenemos en todo momento durante la ejecución de nuestras funciones.
            </p>
        </div>
        <div class="items">
            <div class="one-five">
                <div class="item">
                    <div class="icon"><span>I</span></div>
                    <div class="text">Responsabilidad y compromiso con el cliente</div>
                </div>

                <div class="item">
                    <div class="icon"><span>II</span></div>
                    <div class="text">Trabajo en equipo con armonía y eficiencia</div>
                </div>

                <div class="item">
                    <div class="icon"><span>III</span></div>
                    <div class="text">Conocimiento científico de causa</div>
                </div>

                <div class="item">
                    <div class="icon"><span>IV</span></div>
                    <div class="text">Confidencialidad y secrecía empresarial</div>
                </div>

                <div class="item">
                    <div class="icon"><span>V</span></div>
                    <div class="text">Creatividad e innovación</div>
                </div>
            </div>
            <div class="six-ten">
                <div class="item">
                    <div class="icon"><span>VI</span></div>
                    <div class="text">Inteligencia y adaptación al cambio</div>
                </div>

                <div class="item">
                    <div class="icon"><span>VII</span></div>
                    <div class="text">Autoevaluación y mejora continua</div>
                </div>

                <div class="item">
                    <div class="icon"><span>VIII</span></div>
                    <div class="text">Liderazgo y formación profesional</div>
                </div>

                <div class="item">
                    <div class="icon"><span>IX</span></div>
                    <div class="text">Educación y capacitación continua</div>
                </div>

                <div class="item">
                    <div class="icon"><span>X</span></div>
                    <div class="text">Sentido humano, vocación y espíritu de servicio</div>
                </div>
            </div>
        </div>
    </div>
</div>