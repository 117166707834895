<div [attr.class]="'slider ' + sliderName" id="home">
    <div [attr.class]="sliderName + '-slider-content slider-content'">
        <div class="images" [style.width]="images.length * 100 + '%'">
            <div *ngFor="let img of images; let i = index" [attr.id]="'t' + (i + 1)">
                <div class="background" [style.background-image]="'url(../../assets/slider/' + (sliderName && sliderName.length > 0 ? (sliderName + '/') : '' ) + img + ')'"></div>
            </div>
        </div>
    </div>
    <!-- <div>
        <div class="pages">
            <div *ngFor="let img of images; let i = index" class="page" [ngClass]="{'selected': currentTextItem == i + 1}" (click)="goTo(i + 1)"></div>
        </div>
    </div> -->
</div>