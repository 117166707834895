import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor() { }

  currentItem: number = 1;
  img: string = 'salud_ocupacional_1.jpg';
  interval;

  ngOnInit(): void {
    this.startInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      if (this.currentItem == 5) {
        this.currentItem = 1;
      } else {
        this.currentItem += 1;
      }
    }, 10000);
  }

  selectItem(service): void {
    clearInterval(this.interval);
    this.currentItem = service;
    this.startInterval();
  }

  onHover(img) {
    this.img = img;
  }

}
