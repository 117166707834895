import { Component, OnInit } from '@angular/core';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService) { }
  name: string = "";
  email: string = "";
  subject: string = "";
  message: string = "";
  success: boolean = false;
  error: boolean = false;
  loading: boolean = false;

  ngOnInit(): void {
  }

  submit(): void {
    this.loading = true;
    this.error = false;

    if (this.name && this.email && this.subject && this.message && this.isValidEmail()) {
      this.contactService.sendEmail({
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }).subscribe(() => {
        this.success = true;
      }, err => {
        this.error = true;
      });
    }
  }

  isValidEmail() {
    let mailValid = false;
      'use strict';

      var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (this.email.match(EMAIL_REGEX)){
        mailValid = true;
      }
    return mailValid;
  }

}
