import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  currentTextItem: number = 1;
  maxTextItems: number = 0;
  interval;
  images: string[] = [
    '1.jpg',
    '2.jpg',
    '3.jpg',
    '4.jpg',
    '5.jpg',
    '6.jpg',
    '7.jpg',
    '8.jpg',
    '9.jpg',
    '10.jpg',
    '11.jpg',
    '12.jpg',
    '13.jpg',
    '14.jpg'
  ]

  constructor() { }

  ngOnInit(): void {
      this.maxTextItems = this.images.length;
      this.animate();
  }

  animate(): void {
    this.interval = setInterval(() => {
      const x = document.body.getBoundingClientRect().width;
      if (this.maxTextItems > this.currentTextItem) {
        document.getElementsByClassName('slider-content')[0].scrollTo(x * this.currentTextItem, 0);
        this.currentTextItem += 1;
      } else {
        this.currentTextItem = 1;
        document.getElementsByClassName('slider-content')[0].scrollTo(0, 0);
      }
    }, 3000);
  }

  goTo(page: number): void {
    const x = document.body.getBoundingClientRect().width;
    document.getElementsByClassName('slider-content')[0].scrollTo(x * (page - 1), 0);
    this.currentTextItem = page;

    clearInterval(this.interval);
    this.animate();
  }

}
