import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  currentTextItem: number = 1;
  maxTextItems: number = 0;
  interval;
  @Input() sliderName: string;
  @Input() images: string[] = []

  constructor() { }

  ngOnInit(): void {
      this.maxTextItems = this.images.length;
      this.animate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.goTo(0);
  }

  animate(): void {
    this.interval = setInterval(() => {
      const x = document.body.getBoundingClientRect().width;
      
      if (this.maxTextItems > this.currentTextItem) {
        document.getElementsByClassName(this.sliderName + '-slider-content')[0].scrollTo(x * this.currentTextItem, 0);
        this.currentTextItem += 1;
      } else {
        this.currentTextItem = 1;
        document.getElementsByClassName(this.sliderName + '-slider-content')[0].scrollTo(0, 0);
      }
    }, 3000);
  }

  goTo(page: number): void {
    if (document.getElementsByClassName(this.sliderName + '-slider-content') && document.getElementsByClassName(this.sliderName + '-slider-content')[0]) {
      const x = document.body.getBoundingClientRect().width;
      document.getElementsByClassName(this.sliderName + '-slider-content')[0].scrollTo(x * (page - 1), 0);
      this.currentTextItem = page;

      clearInterval(this.interval);
      this.animate();
    }
  }

}
