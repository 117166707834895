import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor() { }

  images: any[] = [];
  items: any[] = [
    {
      logo: '',
      text: 'CONSULTING',
      images: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
      ]
    },
    {
      logo: '',
      text: 'HEALTH',
      images: [
        '4.jpg',
        '5.jpg',
        '6.jpg',
      ]
    },
    {
      logo: '',
      text: 'PREPAREDNESS',
      images: [
        '7.jpg',
        '8.jpg',
        '9.jpg',
      ]
    },
    {
      logo: '',
      text: 'EMERGENCY',
      images: [
        '10.jpg',
        '11.jpg',
        '12.jpg',
      ]
    },
    {
      logo: '',
      text: 'SAFETY',
      images: [
        '13.jpg',
        '14.jpg',
        '15.jpg',
      ]
    },
    {
      logo: '',
      text: 'FIRE CONTROL',
      images: [
        '16.jpg',
        '17.jpg',
        '18.jpg',
      ]
    },
    {
      logo: '',
      text: 'ON SITE TRAINING',
      images: [
        '19.jpg',
        '20.jpg',
        '21.jpg',
      ]
    },
    {
      logo: '',
      text: 'RISK',
      images: [
        '22.jpg',
        '23.jpg',
        '24.jpg',
      ]
    },
    {
      logo: '',
      text: 'ENVIRONMENTAL',
      images: [
        '25.jpg',
        '26.jpg',
        '27.jpg',
      ]
    },
    {
      logo: '',
      text: 'LEGAL SUPPORT',
      images: [
        '28.jpg',
        '29.jpg',
        '30.jpg',
      ]
    },
    {
      logo: '',
      text: 'HIGH TECH RECON',
      images: [
        '31.jpg',
        '32.jpg',
        '33.jpg',
      ]
    },
    {
      logo: '',
      text: 'CYBERSECURITY',
      images: [
        '34.jpg',
        '35.jpg',
        '36.jpg',
      ]
    },
    {
      logo: '',
      text: 'LABORATORY',
      images: [
        '37.jpg',
        '38.jpg',
        '39.jpg',
      ]
    },
    {
      logo: '',
      text: 'MACHINE',
      images: [
        '40.jpg',
        '41.jpg',
        '42.jpg',
      ]
    },
    {
      logo: '',
      text: 'MAINTENANCE',
      images: [
        '43.jpg',
        '44.jpg',
        '45.jpg',
      ]
    },
    {
      logo: '',
      text: 'CLEANING',
      images: [
        '46.jpg',
        '47.jpg',
        '48.jpg',
      ]
    },
    {
      logo: '',
      text: 'RESEARCH',
      images: [
        '49.jpg',
        '50.jpg',
        '51.jpg',
      ]
    },
    {
      logo: '',
      text: 'ENGINEERING',
      images: [
        '52.jpg',
        '53.jpg',
        '54.jpg',
      ]
    },
    {
      logo: '',
      text: 'AUTOMATION',
      images: [
        '55.jpg',
        '56.jpg',
        '57.jpg',
      ]
    },
    {
      logo: '',
      text: 'LDAR & THERMAL',
      images: [
        '58.jpg',
        '59.jpg',
        '60.jpg',
      ]
    }
  ];

  ngOnInit(): void {
    this.images = this.items[0].images;
  }

  selectService(i: number): void {
    this.images = this.items[i].images;
    this.goTo('slider');
  }

  goTo(section): void {
      document.getElementById(`${section}Section`).scrollIntoView();
  }
}
