<splash *ngIf="showSplashScreen"></splash>
<header [sectionActive]="sectionActive" [menuOpened]="showVerticalMenu" (menuVerticalEvent)="openVerticalMenu($event)" *ngIf="!showSplashScreen"></header>
<div class="content" *ngIf="!showSplashScreen">
  <vertical-menu (closeMenuEvent)="openVerticalMenu($event)" *ngIf="showVerticalMenu"></vertical-menu>
  <slider></slider>
  <who-we-are></who-we-are>
  <m-v-v></m-v-v>
  <o-p-r></o-p-r>
  <letter></letter>
  <our-services></our-services>
  <traceability></traceability>
  <contact></contact>
  <footer></footer>
</div>