<div class="mvv" id="mvvSection">
    <div id="mvv" style="height: 50px; width: 100px;"></div>
    <br><br><br>
    <div class="mvv-content">
        <div class="card">
            <div class="card-content">
                <div class="icon mision"></div>
                <h1>MISIÓN</h1>
                <div class="text justify">
                    Proveer a nuestros clientes de soluciones integrales para cada una de sus necesidades en el
                    área de la prevención, protección, conservación de la salud y la seguridad de sus empleados
                    y bienes, colaborando en equipo con espíritu de servicio, sentido humano y mejor eficiencia
                    posible dentro de los más altos estándares de calidad y profesionalismo en el mercado.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-content">
                <div class="icon vision"></div>
                <h1>VISIÓN</h1>
                <div class="text justify">
                    Ser una empresa de elevada competencia y en constante mejora continua, líder a nivel
                    nacional y reconocida por su excelencia, profesionalismo, calidad, sentido humano y
                    responsabilidad social con que realizamos nuestro trabajo. Ser una empresa vanguardista
                    que incorpore en todo ámbito la legalidad, el desarrollo sustentable y la integración
                    armoniosa con nuestros clientes.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-content">
                <div class="icon values"></div>
                <h1>VALORES</h1>
                <div class="text justify">
                    En VANDER RISK CONTROL DE MÉXICO S.A. DE C.V. nos regimos incansablemente con
                    honestidad, verdad, eficiencia, responsabilidad, compromiso, confidencialidad, creatividad,
                    sentido humano y espíritu de servicio para garantizar a nuestros clientes la confiabilidad que
                    merecen y los resultados que desean.
                </div>
            </div>
        </div>
    </div>
</div>