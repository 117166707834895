<div class="who-we-are" id="whoWeAreSection">
    <div class="section-content">
        <br><br>
        <h1 id="whoWeAre">¿QUIÉNES SOMOS?</h1>
        <div class="text">
            <p class="justify">
                Somos una empresa orgullosamente mexicana y altamente comprometida con nuestros
                clientes en materia de seguridad industrial, salud ocupacional, medicina preventiva, protección
                civil, protección al medio ambiente, preparación para auditorías e implementación y cumplimiento 
                de la normatividad legal aplicable a las empresas e industrias.<br><br>
                Como equipo de trabajo y prestadores de servicios, ofrecemos a nuestros clientes la búsqueda
                de la integración armoniosa como colaboradores de primera mano para lograr la seguridad y
                salud de sus trabajadores en su empresa, la planeación e implementación exitosa de
                proyectos, el mantenimiento de sistemas organizados y la ejecución de servicios y actividades
                basadas en la autogestión para lograr los objetivos medibles en beneficio de nuestros clientes
                para mantener el bienestar y la integridad de sus colaboradores.<br><br>
                Nuestros servicios cuentan con el más alto estándar de calidad, evaluación interna y mejora
                continua para mantener a nuestros clientes satisfechos, seguros y con la certeza de que
                cuentan con nosotros como su soporte y apoyo. Buscamos en todo momento garantizar a
                nuestros clientes la mayor eficiencia, la solución a sus problemas, el mantenimiento de
                sistemas de gestión y el alcance de los mejores resultados posibles.
            </p>
            <br>
            <div style="text-align: center;">
                <p>
                    [ PROMOVEMOS EL TRABAJO ENFOCADO EN SISTEMAS INTEGRADOS DE GESTIÓN ]<br>
                    [ PROTEGEMOS LA SEGURIDAD Y SALUD DE LAS PERSONAS, EL MEDIO AMBIENTE Y LAS EMPRESAS ]<br>
                    [ CUIDAMOS LA VIDA HUMANA, SUS PROCESOS, MAQUINARIAS, INSTALACIONES Y MEDIO AMBIENTE ]<br> 
                    [ PROTEGEMOS LA INTEGRIDAD DEL SER HUMANO, SU INTERACCIÓN CON LA MÁQUINA Y SU ENTORNO ]<br>
                </p>
                <br>
                <p>
                    BRINDAMOS ASESORÍA, CONSULTORÍA & ATENCIÓN PARA LAS EMPRESAS E INDUSTRIAS EN: <br>
                    SEGURIDAD INDUSTRIAL | SALUD OCUPACIONAL | MEDICINA PREVENTIVA | EXÁMENES MÉDICOS PERIÓDICOS |
                    ZONA PROTEGIDA | SEGURIDAD SANITARIA | PROTECCIÓN VS INCENDIOS | PROTECCIÓN AMBIENTAL | CAPACITACIONES
                    PROTECCIÓN CIVIL | CONTROL DE CALIDAD | ANÁLISIS DE RIESGOS | ASESORÍA LEGAL STPS | GESTORÍA & TRAMITOLOGÍA
                </p>
            </div>
        </div>
    </div>
</div>