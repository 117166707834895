import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss']
})
export class LetterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
